@import url('./assets/fonts/mulish/style.css');

:root {
  --brand-primary: #0aaab8;
  --brand-gray: #324b4e;
  --brand-gray-lighter: #95b0b4;
}

html {
  font-family: Mulish, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

body {
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
}

.p-datatable.p-datatable-xs .p-datatable-header {
  padding: 0.3rem 0.5rem;
}
.p-datatable.p-datatable-xs .p-datatable-thead > tr > th {
  padding: 0.3rem 0.5rem;
}
.p-datatable.p-datatable-xs .p-datatable-tbody > tr > td {
  padding: 0.2rem 0.5rem;
}
.p-datatable.p-datatable-xs .p-datatable-tfoot > tr > td {
  padding: 0.3rem 0.5rem;
}
